.services-section {
  background-image: linear-gradient(to bottom right, #34d399, #0e9f6e);
  padding: 50px 0;
  text-align: center;
  color: #fff;
  font-family: 'Arial', sans-serif;
  padding-top: 150px;
  padding-bottom: 150px;
}

.services-title {
  font-size: 3rem;
  margin-bottom: 30px;
  font-weight: bold;
}

.services-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.service-card {
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 300px;
  width: 100%;
  transition: transform 0.3s ease;
}

.service-card:hover {
  transform: translateY(-10px);
}

.service-title {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.service-description {
  font-size: 1rem;
  line-height: 1.5;
}
