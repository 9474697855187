.testimonials {
    text-align: center;
    padding: 20px;
    background-image: url('hero-image.png');
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: 100px;
    padding-bottom: 100px;
  }
  
  .testimonials h2 {
    margin-bottom: 20px;
    font-size: 2.4em;
    color: #000000;
  }
  
  .instagram-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 10px;
  }
  