.our-work-section {
    max-width: 800px;
    margin: auto;
    padding: 20px;
    text-align: center;
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .our-work-section h2{
    margin-bottom: 30px;
    font-size: 2rem;
  }
  
  .our-work-section .carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .our-work-section .instagram-media {
    width: 100%;
    max-width: 540px;
  }
  