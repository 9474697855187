.student-reviews-container {
    text-align: center;
    padding: 20px;
    padding-bottom: 100px;
    padding-top: 100px;
  }
  
  .student-reviews-heading {
    margin-bottom: 20px;
    font-size: 2em;
    color: #333;
  }
  
  .carousel .slide img {
    object-fit: cover;
    width: 700px;
    height: 700px;
  }
  

@media(max-width:700px){
    .carousel .slide img {
        width: 360px;
        height: 360px;
    }
    .student-reviews-container {
        padding: 10px;
        padding-bottom: 100px;
        padding-top: 100px;
    }
}