.blogs-section {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    text-align: center;
    padding-top: 150px;
  }
  
  .blogs-section  .card-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
  }
  
  .blogs-section  .card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .blogs-section  .instagram-media {
    width: 100%;
    max-width: 540px;
  }
  