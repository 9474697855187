.hero-section {
    height: 100vh;
    background: url('hero-image2.png');
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: 'Rubik', sans-serif;
    padding-bottom: 40px;
    padding-top: 50px;


    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

}

.hero-section h1 {
    font-size: 8rem;
    color: white;
    text-shadow: 2px 2px 1px white;
}

/* .hero-section p{
    font-size: 1.5rem;
    padding-left: 300px;
    padding-right: 300px;
    color: white;
    padding-bottom: 20px;
} */

.buttons {
    display: flex;
    flex-wrap: wrap;
}

.buttons .button-41,
.buttons .button-42 {
    width: 230px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 10px;
    font-size: 1.2rem;
}

.buttons .button-42 {
    background-color: initial;
    text-decoration: none;
    background-image: linear-gradient(-180deg, #c9c9c9, #ffffff);
    border-radius: 5px;
    box-shadow: rgba(0, 0, 0, 0.1) 0 2px 4px;
    color: #575757;
    cursor: pointer;
    font-family: Inter,-apple-system,system-ui,Roboto,"Helvetica Neue",Arial,sans-serif;
    line-height: 44px;
    outline: 0;
    overflow: hidden;
    padding: 0 20px;
    pointer-events: auto;
    position: relative;
    text-align: center;
    touch-action: manipulation;
    user-select: none;
    -webkit-user-select: none;
    vertical-align: top;
    white-space: nowrap;
    z-index: 9;
    border: 0;
}

.button-42:hover {
    background-image: linear-gradient(-180deg, #c9c9c9, rgb(152, 152, 152));
}

@media(max-width:900px){
    
.hero-section h1 {
    font-size: 2.1rem;
    text-shadow: none;
    padding: 10px;
    text-align: center;
}
.hero-section p{
    font-size: 1.5rem;
    padding-left: 20px;
    padding-right: 20px;
    color: white;
    padding-bottom: 20px;
}

.buttons{
    justify-content: center;
    align-items: center;
}

.buttons .button-41,
.buttons .button-42 {
    width: 220px;
    height: 40px;
}

.buttons{
    padding-top: 1px;
}
}


  /* Form success message styles */
  .form-success {
    color: #28a745; /* Green color */
    font-size: 12px;
    font-weight: bold;
    margin-top: 15px;
    text-align: center;
    padding: 0;
  }