.membership-banner-container {
    background-color: #d1f7c4;
    text-align: center;
    padding: 60px 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .membership-banner-heading {
    font-size: 2.5rem;
    color: #2c3e50;
    margin-bottom: 40px;
    font-weight: bold;
  }
  
  .membership-banner-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 30px;
  }
  
  .membership-banner-item {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    transition: transform 0.3s ease;
    width: 450px;
    text-align: left;
  }
  
  .membership-banner-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }
  
  .membership-banner-icon {
    font-size: 3rem;
    color: #4caf50;
    margin-right: 15px;
  }
  
  .membership-banner-item h3 {
    font-size: 1.75rem;
    margin-bottom: 10px;
    color: #2c3e50;
  }
  
  .membership-banner-item p {
    margin: 0;
    color: #666;
    font-size: 1rem;
  }
  
  .membership-banner-item ul {
    list-style-type: disc;
    padding-left: 20px;
    color: #666;
    font-size: 1rem;
  }
  
  .membership-banner-item ul li {
    margin-bottom: 5px;
  }
  
  .membership-banner-button {
    display: inline-block;
    margin-top: 20px;
    padding: 10px 20px;
    color: white;
    background-color: #4caf50;
    border: none;
    border-radius: 5px;
    text-decoration: none;
    font-size: 1rem;
    transition: background-color 0.3s ease;
  }
  
  .membership-banner-button:hover {
    background-color: #388e3c;
  }
  