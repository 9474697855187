.about-page {
    padding: 20px;
    padding-top: 130px;
}

.about-section {
    margin-bottom: 40px;
    padding-left: 100px;
    padding-right: 100px;
}

.about-section ul{
    padding-left: 40px;
    padding-right: 20px;
}

.about-section p{
    text-align: justify;
}

.about-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.about-image {
    width: 100%;
    max-width: 500px;
    margin-bottom: 20px;
}

.about-page .services-section,
.team-section {
    margin-bottom: 40px;
}

.about-page .services-section h2,
.team-section h2 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.about-page .services,
.team-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
}

.about-page .service-card,
.team-card {
    background-color: #f9f9f9;
    border-radius: 10px;
    padding: 20px;
    width: calc(50% - 10px);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease;
}

.about-page .service-card:hover,
.team-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.about-page .service-card h3,
.team-card h3 {
    margin-top: 0;
}

.about-page .service-card p,
.team-card p {
    margin-bottom: 0;
}

.team-image {
    width: 200px;
    border-radius: 50%;
    margin-bottom: 10px;
}

@media (max-width: 768px) {
    .about-page .service-card,
    .team-card {
        width: 100%;
    }

    .about-section{
        padding-left: 10px;
        padding-right: 10px;
        text-align: justify;
    }
}
