.certifications-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    padding-top: 120px;
}

.certifications-heading {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 30px;
}

.certificates {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
}

.certificate {
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.certificate-image {
    width: 100%;
    height: auto;
}

.certificate-details {
    padding: 20px;
}

.certificate-title {
    font-size: 1.5rem;
    margin-bottom: 10px;
}

.certificate-description {
    font-size: 1rem;
    margin-bottom: 10px;
}

.certificate-timing {
    color: #666;
    font-size: 0.9rem;
}
