
.banner-container {
    background-image: url('hero-image.png');
    text-align: center;
    padding: 50px 20px;

    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.banner-heading {
    font-size: 2.5rem;
    color: #ffffff;
    margin-bottom: 40px;
}

.banner-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: wrap;
    gap: 40px;
}

.banner-item {
    background-color: #fff;
    border-radius: 10px;
    padding: 30px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    align-items: center;
    width: 300px;
    transition: transform 0.3s ease;
    height: 150px;
}

.banner-item:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
}

.banner-icon {
    font-size: 2rem;
    color: #34d399;
    margin-right: 15px;
}

.banner-item h3 {
    font-size: 1.25rem;
    margin-bottom: 10px;
}

.banner-item p {
    margin: 0;
    color: #666;
    font-size: 1rem;
}

.banner-item a {
    color: #34d399;
    text-decoration: none;
    margin-top: 10px;
    display: inline-block;
}

.banner-item a:hover {
    text-decoration: underline;
}