/* Navbar.css */

.navbar {
    background-color: #393939;
    color: #ffffff;
    box-shadow: 1px 1px 10px gray;
    padding: 1px;
    z-index: 10;
    position: fixed;
    width: 100%;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .navbar-logo {
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .brand-name{
    color: white;
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .navbar-logo img{
    height: 80px;
    width: 80px;
    padding: 8px;
  }
  
  
  .navbar-links {
    display: flex;
  }
  
  .navbar-links a {
    color: #d6d6d6;
    text-decoration: none;
    margin-left: 20px;
    margin-right: 20px;
  }
  
  .navbar-links a:hover {
    text-decoration: underline;
    text-underline-offset: 10px;
    text-decoration-thickness: 2px;
  }
  
  .navbar-toggle button {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
    color: #ffffff;
    display: none;
  }
  
  .navbar-view-menu button {
    background-color: #4aad4a;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }
  
  .navbar-view-menu2 button {
    background-color: #4aad4a;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }
  
  .menu-btn {
    text-decoration: none;
    background-color: #4aad4a;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 5px;
  }
  
  .navbar-view-menu2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
  }
  
  .navbar-view-menu2 button {
    display: none;
  }
  
  @media screen and (max-width: 768px) {
    .navbar-links {
      display: none;
    }
  
    .navbar-toggle button {
      display: block;
    }
  
    .navbar-links-mobile {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: 4rem;
      right: 0;
      background-color: #ffffff;
      box-shadow: 1px 1px 10px gray;
      width: 50%;
      text-align: center;
      border-radius: 10px;
      margin: 10px;
      z-index: 10;
    }
  
    .navbar-links-mobile a {
      color: #000000;
      text-decoration: none;
      padding: 1rem;
    }
  
    .navbar-view-menu2 button {
      display: block;
    }
  
    .navbar-view-menu .button-41 {
      display: none;
    }
  
    .navbar-logo img{
      height: 50px;
      width: 50px;
      padding: 3px;
    }
  
    .navbar{
      padding: 5px 14px; 
    }
    
  }


  .popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7); /* Darker overlay for better focus on the form */
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
    z-index: 1000; /* Ensure it's above other content */
  }
  
  .popup-form {
    background-color: rgba(255, 255, 255, 0.9); /* Semi-transparent background */
    padding: 30px; /* Increased padding for better spacing */
    border-radius: 12px; /* Slightly more rounded corners */
    max-width: 400px; /* Increased max-width for better readability */
    width: 90%; /* Responsive width */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    animation: slide-down 0.3s ease-out; /* Smooth slide-down animation */
  }
  
  @keyframes slide-down {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  .popup-form h2 {
    margin-bottom: 15px;
    font-size: 1.5em; /* Larger font size for the heading */
    color: #333; /* Darker color for better readability */
  }
  
  .form-group {
    margin-bottom: 15px; /* Slightly less margin for better spacing */
  }
  
  .navbar label {
    display: block;
    margin-bottom: 8px; /* Increased margin for better spacing */
    font-weight: bold; /* Bold labels for better emphasis */
    color: #555; /* Slightly darker color */
  }
  
  .navbar input[type="text"],
  .navbar input[type="email"],
  .navbar input[type="tel"],
  .navbar input[type="date"],
  .navbar textarea,
  .navbar select {
    width: 100%;
    padding: 12px; /* Increased padding for better touch target size */
    border: 1px solid #cbcbcb; /* Lighter border color */
    border-radius: 6px; /* Slightly more rounded corners */
    box-sizing: border-box; /* Ensure padding and border are included in the element's total width and height */
    font-size: 1em; /* Consistent font size */
    background-color: rgba(172, 172, 172, 0.116); /* Solid white background for inputs */
    transition: border-color 0.3s;
    color: #000000; /* Smooth transition for border color */
  }
  
  .navbar input[type="text"]:focus,
  .navbar input[type="email"]:focus,
  .navbar input[type="tel"]:focus,
  .navbar input[type="date"]:focus,
  .navbar textarea:focus,
  .navbar select:focus {
    border-color: #007bff; /* Change border color on focus */
    outline: none; /* Remove default outline */
  }
  
  textarea {
    resize: vertical; /* Allow vertical resizing only */
    min-height: 100px; /* Minimum height for better usability */
  }
  
  .submit-button,
  .close-form-button {
    padding: 12px 24px; /* Increased padding for better touch target size */
    margin-right: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em; /* Consistent font size */
    transition: background-color 0.3s; /* Smooth transition for background color */
  }
  
  .submit-button {
    background-color: #28a745; /* Green background for the submit button */
    color: #fff;
  }
  
  .submit-button:hover {
    background-color: #218838; /* Darker green on hover */
  }
  
  .close-form-button {
    background-color: #dc3545; /* Red background for the close button */
    color: #fff;
  }
  
  .close-form-button:hover {
    background-color: #c82333; /* Darker red on hover */
  }
  
  .close-form-button:focus,
  .submit-button:focus {
    outline: 2px solid #333; /* Visible focus outline for better accessibility */
  }
  
  @media (max-width: 480px) {
    .popup-form {
      padding: 20px; /* Reduced padding on smaller screens */
    }
  
    .submit-button,
    .close-form-button {
      padding: 10px 20px; /* Adjust button padding for smaller screens */
    }
  }

  /* Form success message styles */
.form-success {
  color: #28a745; /* Green color */
  font-size: 12px;
  font-weight: bold;
  margin-top: 15px;
  text-align: center;
}