.contact-section {
    background-image: linear-gradient(to bottom right, #34d399, #0e9f6e);
    color: white;
    padding: 50px 20px;
    height: 100vh;
    padding-top: 120px;
  }
  
  .contact-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    margin: 0 auto;
  }
  
  .contact-info {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 30px;
  }
  
  .info-item {
    margin-bottom: 20px;
  }
  
  .contact-form {
    width: 100%;
    max-width: 400px;
  }
  
  .contact-form h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }
  
  .form-group input,
  .form-group textarea {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    border: none;
    background-color: rgba(255, 255, 255, 0.1);
    color: rgb(0, 0, 0);
  }
  
  .form-group textarea {
    resize: none;
  }
  
  .contact-section button[type="submit"] {
    background-color: #fff;
    color: #0e9f6e;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .contact-section button[type="submit"]:hover {
    background-color: #0e9f6e;
    color: white;
  }

  .info-item a{
    text-decoration: none;
    color: white;
  }
  