.statistics-section {
  background-image: linear-gradient(to bottom right, #34d399, #0e9f6e);
  color: white;
  padding: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.statistics-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 50%;
}

.statistics {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}

.statistic {
  text-align: center;
  width: calc(50% - 10px);
  padding: 20px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}

.statistic h3 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.statistic p {
  font-size: 1.25rem;
}

.statistic-icon {
  font-size: 2.5rem;
  margin-bottom: 10px;
}

.founder-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  width: 50%;
  padding: 100px;
}

.founder-photo {
  width: 400px;
  height: 300px;
  background-image: url('founder-photo.png');
  background-size: cover;
  background-position: center;
  border-radius: 10px;
}

.founder-quote {
  max-width: 400px;
  text-align: center;
}

.founder-quote h2 {
  font-size: 2rem;
  margin-bottom: 20px;
  text-align: center;
}

.founder-quote p {
  font-size: 1.25rem;
}


@media(max-width:900px){
.statistics-section{
  flex-direction: column;
}

.founder-section{
  width: 90%;
  padding: 10px;
}

.founder-quote{
  padding: 10px;
  text-align: justify;
}

.statistics-container{
  width: 90%;
  padding-bottom: 50px;
}

.statistic h3 {
  font-size: 0.9rem;
  margin-bottom: 10px;
}

.statistic p {
  font-size: 1rem;
}

}