.courses-section {
    background-image: linear-gradient(to bottom right, #34d399, #0e9f6e);
    padding: 50px 0;
    text-align: center;
    color: #fff;
    font-family: 'Arial', sans-serif;
    padding-top: 140px;
    display: flex;
  }
  
  .courses-title {
    font-size: 3rem;
    margin-bottom: 30px;
    font-weight: bold;
  }
  
  .carousel-container {
    max-width: 800px;
    margin: 0 auto 50px;
  }

  .courses-section  .carousel .slide img{
    width: 600px;
    height: 600px;
}
  .application-form {
    background-color: rgba(255, 255, 255, 0.1);
    padding: 20px;
    border-radius: 10px;
    max-width: 600px;
    margin: 0 auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .application-form h2 {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .application-form form {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  
  .application-form label {
    font-size: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  
  .application-form input,
  .application-form textarea {
    width: 100%;
    padding: 10px;
    border: none;
    border-radius: 5px;
    margin-top: 5px;
  }
  
  .application-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #0e9f6e;
    color: #fff;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .application-form button:hover {
    background-color: #34d399;
  }
  

  @media(max-width:900px){
    .courses-section{
        flex-direction: column;
    }

    .courses-section  .carousel .slide img{
        height: 360px;
        width: 360px;
    }

    .carousel-container{
        max-width: 400px;
        margin: 10px;
    }

    .courses-title{
        font-size: 2rem;
    }
  }

  .courses{
    display: flex;
    flex-direction: column;
  }